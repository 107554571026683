<template>
  <section>
    <section>
      <pm-Breadcrumb :home="home" :model="items_bread" />
      <br>
      <div class="form-elements">
        <div class="row">
          <div class="col-md-9">
            <h2 class="titulo-links-escolha">Responder as perguntas do formulário</h2>
          </div>
          <div class="col-md-3" >
            <pm-Button type="button" style="float: right;margin-top: 5px;"
              @click="trocarTurma()" label="Trocar de Turma"
              class="p-button-danger" icon="pi pi-angle-left" iconPos="left"
            ></pm-Button>
          </div>
        </div>
      </div>
      <div :class="'col-sm-4 col-md-12 col-lg-6 col-xl-6 col-xxl-3'">
        <div class="pm-card">
          <div class="col-md-12" style="margin-bottom: 20px">
            <label for="escola">Selecione o período:</label>
            <select
              class="p-inputtext p-component"
              v-model="periodoSelected"
              @change="buscarForumalarioPerguntas(); buscaResposta()"
              style="appearance: revert !important; width: 100% !important"
            >
              <option value="" disabled selected>
                -- Escolha um período --
              </option>
              <option v-for="n in periodos" :key="n" :value="n">
                {{ n.nome }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="pm-card">
        <h3 style="margin-top:15px;font-weight:400;;margin-bottom: 20px;">
          Aluno(a): {{ nomeAluno }}
        </h3>

        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
              <tr>
                <th class="th_per1" style="width: 15%">PERGUNTA</th>
                <th v-if="periodoSelected.nome" class="th_per1 th_pn_aluno">RESPOSTA {{ periodoSelected.nome.toUpperCase() }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) of this.listaPerguntasFormulario" :key="index">
                <td style="text-align: center; color:rgb(17 18 18); font-weight:normal">
                  {{ item.pergunta }}
                </td>
                <td style="text-align: center; color:rgb(17 18 18); font-weight:normal">
                  <div class="col-md-12" v-if="item.multipla_escolha === 1">
                    <fieldset >
                      <va-radio
                        v-model="item.resposta_objetiva"
                        option="option1"
                        label="Sim"
                      />
                      <va-radio
                        v-model="item.resposta_objetiva"
                        option="option2"
                        label="Não"
                      />
                      <va-radio
                        v-model="item.resposta_objetiva"
                        option="option3"
                        label="CD"
                      />
                      <va-radio
                        v-model="item.resposta_objetiva"
                        option="option4"
                        label="ED"
                      />
                    </fieldset>
                  </div>
                  <div class="col-md-6" v-else >
                    <textarea  class="form-control" v-model="item.resposta_escolha" rows="4"></textarea>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div style="text-align: center;">
            <b>Legenda:</b> Com Dificuldade(CD), Em Desenvolvimento(ED)
          </div>
          <div class='col-md-12 grid justify-content-end mt-5'>
            <va-button style="margin-left:8px;" class="btn btn-danger" color="danger" @click="this.$router.push({name: 'crecheFormulario'});">Voltar</va-button>
            <button class="btn btn-primary" v-if="temResp" @click="editarRespostas">Editar respostas</button>
            <button class="btn btn-primary" v-else @click="enviarRespostas">Salvar respostas</button>
          </div>
        </div>
      </div>

    </section>
  </section>
</template>


<script>
import { defineComponent } from 'vue'
import { Turma } from "@/class/turma";
import { Pergunta } from "@/class/pergunta";
import { Creche } from "@/class/creche";

export default defineComponent({
  props: {
    id:{
      required: true
    },
    turma_id:{
      required: true
    },
    professor_id:{
      required: true
    },
    turma_disciplina_id:{
      required: true
    },
    segmento:{
      required: true
    },
    dataSelecionada:{
      required:true,
    },
    escola_id:{
      required:true,
    },
    serie_id:{
      required:true,
    },
    turno:{
      required:true,
    },
    pergunta_id:{
      required:true,
    },
    respondido:{
      required:true,
    },
    anoAtual: {
      required: true
    },
    idAluno: {
      required: true
    },
    nomeAluno: {
      required: true
    }
  },
  data () {
    const columns = [
      {key: 'nome', label:'Nome do aluno', sortable: true },
      {key: 'resposta', label:'Resposta', sortable: true },
    ]
    return {
      home: {icon: 'pi pi-home', to: '/ambiente-professor/turmas-professor'},
      items_bread: [
        {label: ''},
      ],
      st_id: null,
      st_turma_id: null,
      st_professor_id: null,
      st_turma_disciplina_id:null,
      st_segmento:null,
      st_dataSelecionada:null,
      st_escola_id:null,
      st_serie_id:null,
      st_turno:null,
      st_pergunta_id:null,
      st_aluno:null,
      perguntaASerFeita:[],
      alunosDaTurma:[],
      turma_dados:[],
      filter: '',
      columns,
      perPage: 20,
      currentPage: 1,
      periodos: [
        {id: 1, nome: '1° periodo'},
        {id: 2, nome: '2° periodo'},
        {id: 3, nome: '3° periodo'},
        {id: 4, nome: '4° periodo'},
      ],
      periodoSelected: '',
      listaPerguntasFormulario: [],
      temResp: false,
    }
  },
  computed: {
    pages () {
      return (this.perPage && this.perPage !== '0')
          ? Math.ceil(this.alunosDaTurma.length / this.perPage)
          : this.alunosDaTurma.length
    },
  },
  methods:{
    async buscaResposta(){
      const data = await Creche.respostasCrecheShowAllAlunoPeriodo(this.periodoSelected.id, this.idAluno, this.listaPerguntasFormulario[0].tipo);
      return data.data;
    },
    async buscarForumalarioPerguntas() {
      const opcaoParaValor = {
          "option1": 1,
          "option2": 0,
          "option3": 3,
          "option4": 4
        };
      const data = await Pergunta.obtemTodos(this.st_serie_id,
      this.anoAtual,
      this.pergunta_id);
      this.listaPerguntasFormulario = [];

      for (const el of data.data) {
        const novo = {
          id: el.id,
          pergunta: el.pergunta,
          multipla_escolha: el.multipla_escolha,
          ano: el.ano,
          tipo: el.tipo,
          resposta_escolha: '',
          resposta_objetiva: '',
          periodo: this.periodoSelected.id,

        }
        this.listaPerguntasFormulario.push(novo);

      }

      const temResp = await this.buscaResposta();
      if(temResp.length > 0){

        for (let index = 0; index < this.listaPerguntasFormulario.length; index++) {
          this.listaPerguntasFormulario[index].idResposta = temResp[index].id;
          this.listaPerguntasFormulario[index].resposta_escolha = temResp[index].resposta_escolha;
          temResp[index].resposta_objetiva !== -1 ? this.listaPerguntasFormulario[index].resposta_objetiva = Object.keys(opcaoParaValor).find(key => opcaoParaValor[key] === temResp[index].resposta_objetiva)
          :this.listaPerguntasFormulario[index].resposta_objetiva = ''
        }
        this.temResp = true;
      }else{
        this.temResp = false;
      }

    },
    async editarRespostas(){
      try {
        const opcaoParaValor = {
          "option1": 1,
          "option2": 0,
          "option3": 3,
          "option4": 4
        };

        for (const el of this.listaPerguntasFormulario) {
          if (el.resposta_objetiva != '') {

            el.resposta_objetiva =
              opcaoParaValor.hasOwnProperty(el.resposta_objetiva) ? opcaoParaValor[el.resposta_objetiva] : null;
            el.resposta_escolha = "-1";
            el.periodo = this.periodoSelected.id;
            el.idAluno = this.idAluno;
            el.turma_id = this.st_turma_id;

          }
          else if (el.resposta_escolha != '') {

            el.resposta_escolha = el.resposta_escolha;
            el.resposta_objetiva = -1;
            el.periodo = this.periodoSelected.id;
            el.idAluno = this.idAluno;
            el.turma_id = this.st_turma_id;
          }

        };
        const data = await Creche.updateRespostasCrecheAlunoPeriodo(this.listaPerguntasFormulario);
        this.$vaToast.init({
          message: "Resposta editada com sucesso!",
          iconClass: 'fa-star-o',
          position: 'top-right',
          color:'success',
          duration: 3000,
          fullWidth: false,
        });
        await this.buscarForumalarioPerguntas();
        // this.$router.push({ name: "crecheFormulario" });
      } catch (error) {
        this.$vaToast.init({
          message: "Problema ao editar as respostas",
          iconClass: 'fa-star-o',
          position: 'top-right',
          color:'danger',
          duration: 3000,
          fullWidth: false,
        });
      }

    },
    async enviarRespostas() {
      try {
        const opcaoParaValor = {
          "option1": 1,
          "option2": 0,
          "option3": 3,
          "option4": 4
        };

        for (const el of this.listaPerguntasFormulario) {
          if (el.resposta_objetiva != '' && el.resposta_objetiva != -1) {
            el.resposta_objetiva =
              opcaoParaValor.hasOwnProperty(el.resposta_objetiva) ? opcaoParaValor[el.resposta_objetiva] : null;
            el.resposta_escolha = "-1";
            el.periodo = this.periodoSelected.id;
            el.idAluno = this.idAluno;
            el.turma_id = this.st_turma_id;

          }
          else if (el.resposta_escolha != '') {
            el.resposta_escolha = el.resposta_escolha;
            el.resposta_objetiva = -1;
            el.periodo = this.periodoSelected.id;
            el.idAluno = this.idAluno;
            el.turma_id = this.st_turma_id;
          }
        }
        const data = await Creche.cadastrarRespostas(this.listaPerguntasFormulario);
        this.$vaToast.init({
          message: "Resposta enviada com sucesso!",
          iconClass: 'fa-star-o',
          position: 'top-right',
          color:'success',
          duration: 3000,
          fullWidth: false,
        });
        await this.buscarForumalarioPerguntas();
        // this.$router.push({ name: "crecheFormulario" });
      }
      catch(e) {
        if (e.response.status === 400) {

          this.$vaToast.init({
            message: e.response.data.erro,
            iconClass: 'fa-star-o',
            position: 'top-right',
            color:'danger',
            duration: 3000,
            fullWidth: false,
          });
        }else{

          this.$vaToast.init({
            message: "Problema ao enviar a resposta",
            iconClass: 'fa-star-o',
            position: 'top-right',
            color:'danger',
            duration: 3000,
            fullWidth: false,
          });
        }
      }
    },

    trocarTurma(){
      this.$router.push({ name: "turmas-Professor" });
    },


  },
  async beforeMount() {
    if(this.id == null ||  this.turma_id == null){
      this.st_id = sessionStorage.getItem("Professor_id");
      this.st_turma_id = sessionStorage.getItem("Professor_turma_id");
      this.st_professor_id = sessionStorage.getItem("Professor_professor_id");
      this.st_turma_disciplina_id = sessionStorage.getItem("Professor_turma_disciplina_id");
      this.st_segmento = sessionStorage.getItem("Professor_segmento");
      this.st_escola_id = sessionStorage.getItem("Professor_escola_id");
      this.st_serie_id = sessionStorage.getItem("Professor_serie_id");
      this.st_turno = sessionStorage.getItem("Professor_turno");
      this.st_dataSelecionada = sessionStorage.getItem("Professor_dataSelecionada");
      this.st_pergunta_id = this.pergunta_id;


    }else{
      this.st_id = this.id;
      this.st_turma_id= this.turma_id;
      this.st_professor_id = this.professor_id;
      this.st_turma_disciplina_id =this.turma_disciplina_id;
      this.st_segmento =this.segmento;
      this.st_escola_id =this.escola_id;
      this.st_serie_id =this.serie_id;
      this.st_turno =this.turno;
      this.st_dataSelecionada = this.dataSelecionada;
      this.st_pergunta_id = this.pergunta_id;

    }
    const turma = {
        turma_id: this.st_turma_id,
        etapa_id: this.st_segmento,
        periodo_id: this.st_serie_id,
        escola_id: this.st_escola_id,
    }
    const resp_turma = await Turma.aturma(turma);
    const  tt = [
        {
          label: resp_turma.data[0].escola
        },
        {
          label: resp_turma.data[0].etapa
        },
        {
          label: resp_turma.data[0].periodo
        },
        {
          label: resp_turma.data[0].turma
        },
        {
          label: resp_turma.data[0].turno
        },
    ];
    this.items_bread = tt;
    this.turma_dados = resp_turma.data;
    this.periodoSelected = this.periodos[0];
    await this.buscarForumalarioPerguntas();
  }
})
</script>


<style lang="scss" scoped>
  .table-example--pagination {
    padding-top: 20px;
    text-align: center;
    text-align: -webkit-center;
  }
  .cabecario-turma{
    background-color:#2fc982;
    color: #fff;
    margin-right: 10px;
    padding: 8px;
    border-radius: 5px;
    text-transform: uppercase;
  }
  @media(max-width:500px){
    .titulo-links-escolha {
      font-size: 24px;
    }
  }
</style>

<style scoped>
  .th_per1{
    background-color: #f8f9fa !important;
    color: #495057 !important;
    border-right: 1px solid #e9ecef !important;
    border-bottom: 2px solid #e9ecef !important;
    text-align: center;
    padding: 1rem 1rem;
    font-weight: 600;
  }
  .th_pn_aluno{
    width:15%;
  }
  @media(max-width:720px){
    .th_pn_aluno{
      width:90%;
    }
  }

  @media(max-width:500px){
    .cabecario-turma{
      display: inline-grid;
      margin-bottom: 5px;
      font-size: 12px;
    }
  }

  .col-icon-univer{
    padding:0px;padding-top: 0px;padding-left: 10px;width: 4%;
  }
  @media(max-width:500px){
    .col-icon-univer{
      width: 15%;
    }
    .col-cturm{
      width: 80%;
    }
  }

  .escolha-nome-disciplina{
    font-size: 25px;margin-top: 10px;padding-bottom: 15px;
  }

  @media(max-width:500px){
     .escolha-nome-disciplina{
       font-size:16px;
     }
  }

</style>
